var Video = require('twilio-video');

//nice to rewrite to require for unifi, but ... don't know how to get it works:
//import {twilio_video_connect, twilio_chat_connect} from './current_lesson-code.js.erb';

import {connectTehesoRoom, disconnectTehesoRoom, updateDeviceSelectionOptions, lessonJsLog} from './twilio-utils'

import autosize from 'autosize';
import Stepper from 'bs-stepper';
import Cookies from 'js-cookie';
import JSONFormatter from 'json-formatter-js';
import _ from 'lodash';

var jstz = require('jstimezonedetect/dist/jstz');

var DetectRTC = require('detectrtc');

var videojs = require('video.js/dist/video.js');
window.players = [];

$(document).on('turbolinks:visit', function(){
  var url = window.location.href;
  dataLayer.push({
    'event':'pageView',
    'virtualUrl': url
  });
});

$(document).on('turbolinks:before-visit', function() {
  players.forEach(function(player) {
    player.dispose();
  });
  window.players = [];
});

$(document).on('click', 'a.scroll[href^="#"]', function (event) {
  event.preventDefault();
  $('html, body').animate({
      scrollTop: $($.attr(this, 'href')).offset().top
  }, 500);
});

$(document).on('turbolinks:load', function() {

  if($(".sticky").length){
    var first_sticky_position = $(".sticky")[0].getBoundingClientRect().top;
    window.onscroll = function() {stickySticked($(".sticky")[0], first_sticky_position)}; 
  }
  
  $('[data-toggle="lightbox"]').fancybox();
  
  //var { picked, p } = readaptive('.plyr-player');
  
  //readaptive('.plyr-player');
  
  $('.video-js-html-source').each(function() {
    var video = videojs($(this).attr('id'), {
      fill: true,
      aspectRatio: '16:9',
      controls: true
    });
    window.players.push(video);
  });

  
  if ($(".bs-stepper").length){
    var stepper = new Stepper($('.bs-stepper')[0])
  }
  
  //var newParagraph = document.createElement('p');
  //newParagraph.textContent = 'Offering the browser: ' + picked.join(', ');
  //document.getElementsByClassName('plyr-container')[0].appendChild(newParagraph);

  $(".teacher-gallery").justifiedGallery({
    rowHeight : 100,
    lastRow : 'center',
    margins : 5
  });
  
  if($(".json-formatter").length){
    var json = JSON.parse($(".json-formatter").text());
    const formatter = new JSONFormatter(json, 4);
    $(".json-formatter").html(formatter.render()); 
  }

  if ($(".client-registrations-create").length || $(".client-registrations-new").length){
    $('#client_time_zone').val(jstz.determine().name());
    if ($('.client_invited_by_code').val() == "" && !$('#client_invited_by_code').hasClass("is-invalid")){
      $('.client_invited_by_code').hide();
    } else {
      $('.invited').hide();
    }
    $('.invited a').click(function(){
      $('.client_invited_by_code').show();
      $('.invited').hide();
    });
  }
  
  if ($(".client-profiles-update_first_setup").length || $(".client-profiles-first_setup").length){
    $("#students .nested-fields").first().find(".remove_fields").hide();
    if ($(".form-group.client_middle_name input").val() == ""){
      $(".form-group.client_middle_name").hide();
    }
    else {
      $("a.client_middle_name").parent().hide();
    }
    $("a.client_middle_name").click(function(){
      $(".form-group.client_middle_name").show();
      $("a.client_middle_name").parent().hide();
    });
  }
  
  if ($(".current_lesson").length){

    var deviceSelections = {
      audioinput: null,
      videoinput: null
    };

    async function connectToLesson(){

      updateDeviceSelectionOptions().then(async function(){
        
        await lessonJsLog();

        console.log(document.querySelector('select#audioinput'));
        console.log(document.querySelector('select#audioinput'));
        deviceSelections.audioinput = document.querySelector('select#audioinput');
        deviceSelections.videoinput = document.querySelector('select#videoinput');
        // Whenever a media device is added or removed, update the list.
        navigator.mediaDevices.ondevicechange = updateDeviceSelectionOptions();
        
        var testedAndActiveAudio = [];
        var testedAndActiveVideo = [];
        var testedAudio = undefined;
        var testedVideo = undefined;

        if (Cookies.get('browserId') === undefined){
          $(".untested_environment").removeClass("d-none");
          console.log("Cookies browserId undefined");
        } else {
          console.log({"Cookies browserId": Cookies.get('browserId')});
        }        
          
        if (Cookies.get('testedAudioDevices') === undefined){ 
          $(".untested_audio").removeClass("d-none");
          console.log("Cookies testedAudioDevices undefined");
        } else {
          testedAudio = JSON.parse(Cookies.get('testedAudioDevices'));
          console.log({"Cookies testedAudioDevices": testedAudio});
          if(testedAudio.lenght == 0){
            $(".untested_audio").removeClass("d-none");
            console.log("testedAudio lenght 0");
          }
        }

        if (Cookies.get('testedVideoDevices') === undefined){ 
          $(".untested_video").removeClass("d-none");
          console.log("Cookies testedVideoDevices undefined");
        } else {
          testedVideo = JSON.parse(Cookies.get('testedVideoDevices'));
          console.log({"Cookies testedAudioDevices": testedVideo});
          if(testedVideo.lenght == 0){
            $(".untested_video").removeClass("d-none");
            console.log("testedVideo lenght 0");            
          }
        }

        if (testedAudio != undefined && testedVideo != undefined){
          deviceSelections.audioinput.forEach(function(activeDevice){
            testedAudio.forEach(function(testedDevice){
              if(activeDevice.value == testedDevice.deviceId){
                testedAndActiveAudio.push(testedDevice.deviceId);
              }
            });
          });
          deviceSelections.videoinput.forEach(function(activeDevice){
            testedVideo.forEach(function(testedDevice){
              if(activeDevice.value == testedDevice.deviceId){
                testedAndActiveVideo.push(testedDevice.deviceId);
              }
            });
          });
        }

        console.log({"testedAndActiveAudio": testedAndActiveAudio});
        console.log({"testedAndActiveVideo": testedAndActiveVideo});

        $("select#audioinput").change(function(){
          var found = false;
          if (testedAudio != undefined){
            testedAudio.forEach(function(testedDevice){
              if(deviceSelections.audioinput.value == testedDevice.deviceId){
                found = true;
              }
            });
          }
          if (!found){
            $(".untested_audio_selected").removeClass("d-none");
            console.log("untested_audio_selected");
          } else {
            $(".untested_audio_selected").addClass("d-none");
            console.log("tested_audio_selected");
          }
        });


        $("select#videoinput").change(function(){
          var found = false;
          if (testedVideo != undefined){
            testedVideo.forEach(function(testedDevice){
              if(deviceSelections.videoinput.value == testedDevice.deviceId){
                found = true;
              }
            });
          }
          if (!found){
            $(".untested_video_selected").removeClass("d-none");
            console.log("untested_video_selected");
          } else {
            $(".untested_video_selected").addClass("d-none");
            console.log("tested_video_selected");
          }
        });

        if(testedAndActiveAudio.length == 1 && testedAndActiveVideo.length == 1 && Cookies.get('browserId') != undefined){
          console.log("auto connect");
          await lessonJsLog();
          await connectTehesoRoom(testedAndActiveAudio[0], testedAndActiveVideo[0]);
        } else {
          $("#devices-modal").modal("show");
          console.log("modal connect");
          lessonJsLog();
          $('#devices-modal').on('hidden.bs.modal', async function (e) {
            await connectTehesoRoom(deviceSelections.audioinput.value, deviceSelections.videoinput.value);
          });
        }

        $("#local-media .troubleshooter").hide(); // TODO remove this line
        $("#local-media video").height($("#local-media").height());
        $("#local-media").width(($("#local-media video").width())); //

        $("#remote-media").height($( window ).height());

        $('#button-leave').click(function(){ 
          console.log('Leaving room...');
          disconnectTehesoRoom(); 
          window.location.replace("/"+$('#credentails').data("role")+"/dashboard")
        });

        if ($('#messages .lastmessage').lenght > 0){
          $('#messages .lastmessage').get(0).scrollIntoView();
        }
      
        autosize($('textarea'));

        $("#button-chat").click(function(){
          $(".remote-wrapper").toggleClass("chat-open");
          $("#chat").toggle();
          $("#button-send").toggle();
        });

      }).catch(function(){
        console.log("blocked access to camera or mic");
        $("#blocked-access-modal").modal("show");
        $('#blocked-access-modal').on('hidden.bs.modal', async function (e) {
          location.reload();
        });
      });

    }

    connectToLesson();

  }
  

  if ($(".client-dashboard.invite").length){

    $(".copycodebutton").click(function(){
      var $temp = $("<input>");
      $("body").append($temp);
      $temp.val($("#copycode").text()).select();
      document.execCommand("copy");
      $temp.remove();
      $("#copycode").fadeOut(100).fadeIn(100);
    });

    $(".fb-share").click(function(){
      FB.ui({
        method: 'share',
        href: $(this).attr("data-share-url"),
      }, function(){
        if ("ga" in window) {
          tracker = ga.getAll()[0];
          if (tracker) {
            tracker.send("event", "facebook-link", "share", "invite via facebook link click", {
              hitCallback: function() {
                console.log("ga event sent");
              }
            });
          }
        }
        
      });
    });
    
    $(".email-share").click(function(){
      if ("ga" in window) {
        tracker = ga.getAll()[0];
        if (tracker) {
          tracker.send("event", "email-link", "click", "invite via email link click", {
            hitCallback: function() {
              console.log("ga event sent");
            }
          });
        }
      }

    });
  }
  
  if ($(".client-credit_orders.new").length){
    $(".buy").bind("ajax:beforeSend", 
      function(){
        start_preloader(this);
      }
    );
    $(".buy").bind("ajax:success", function(event) {
      if (event.detail[0]["type"] == "new"){
        _gopay.checkout({gatewayUrl: event.detail[0]["url"], inline: true});
      } else {
        window.location.replace(event.detail[0]["url"]);
      }
    });
    $(".buy").bind("ajax:error", function(){ 
      alert("There's a problem with payment.");
    });
  }

  if ($(".client-lessons.new").length){
    //set_new_lesson_timepicker();
  }
  
  if ($(".client-booking.index").length){
    
    client_booking_index_reactivate();
    
  }
  
  if ($(".client-booking.index, .client-booking.teachers_detail, .client-teachers.show").length){

    $('#new-lesson').on('show.bs.modal', function (e) {
      $(this).find('.modal-body').load(e.relatedTarget.href, function(){
        //set_new_lesson_timepicker();
      });
    });

    $("#booking-form .submit").click(function(){
      $("#booking-form").trigger('submit.rails');
    });

    var startDate = new Date();
    startDate.setDate(startDate.getDate()+1);
    
    //$('.booking-date .date').attr("value", "test");
    $('.booking-date .date').datepicker({
      format: $('.booking-date .date').data("format")
    });

    $('.booking-date .date.start').datepicker('setDate', new Date($("#filterrific_search_by_availability_datetime_start_date").val()));
    $('.booking-date .date.end').datepicker('setDate', new Date($("#filterrific_search_by_availability_datetime_end_date").val()));

    // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
    // January - 0, February - 1, etc.
    $('.booking-date .date.start').datepicker().on('changeDate', function (ev) {
      $("#filterrific_search_by_availability_datetime_start_date").val(ev.date.getFullYear() + '-' + ('0' + (ev.date.getMonth() + 1)).slice(-2) + '-' + ('0' + ev.date.getDate()).slice(-2));
      if(ev.date > $(".booking-date .date.end").datepicker('getDate')){
        $(".booking-date .date.end").datepicker('setDate', ev.date);
      }
    });
    $('.booking-date .date.end').datepicker().on('changeDate', function (ev) {
      $("#filterrific_search_by_availability_datetime_end_date").val(ev.date.getFullYear() + '-' + ('0' + (ev.date.getMonth() + 1)).slice(-2) + '-' + ('0' + ev.date.getDate()).slice(-2));
      if(ev.date < $(".booking-date .date.start").datepicker('getDate')){
        $(".booking-date .date.start").datepicker('setDate', ev.date);
      }
    });

    // initialize input widgets first
    $('.booking-time .time').timepicker({
      'showDuration': false,
      'timeFormat': 'H:i',
      'show2400': true,
      'maxTime': '24:00'
    });

    // todo datepair! at least time
  
  }
  
  
  if ($(".countdown").length){
    
    var element = [];
    var timer = [];
    var display_days = [];
    var display_hours = [];
    var display_minutes = [];
    var display_seconds = [];
    var days = [];
    var hours = [];
    var minutes = [];
    var seconds = [];
    var final_time = [];
    var distance = [];
    var current_time;
    
    $(".countdown").each(function(index){
      element[index] = $(this);
      timer[index] = $(this).find(".seconds_to_end").text();
      display_days[index] = $(this).find(".time_to_next_lesson_days");
      display_hours[index] = $(this).find(".time_to_next_lesson_hours");
      display_minutes[index] = $(this).find(".time_to_next_lesson_minutes");
      display_seconds[index] = $(this).find(".time_to_next_lesson_seconds");

      current_time = new Date().getTime();
      final_time[index] = new Date(current_time + (timer[index]*1000));
      
      setInterval(function() {

        // Get todays date and time
        var now = new Date().getTime();
        
        // Find the distance between now and the count down date
        distance[index] = final_time[index] - now;

        if (distance[index] < 0) {
          distance[index] = 0;
        }

        // Time calculations for days, hours, minutes and seconds
        days[index] = Math.floor(distance[index] / (1000 * 60 * 60 * 24));
        hours[index] = Math.floor((distance[index] % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        minutes[index] = Math.floor((distance[index] % (1000 * 60 * 60)) / (1000 * 60));
        seconds[index] = Math.floor((distance[index] % (1000 * 60)) / 1000);

        display_days[index].text(days[index]);
        display_hours[index].text(zerofill(hours[index],2));
        display_minutes[index].text(zerofill(minutes[index],2));
        display_seconds[index].text(zerofill(seconds[index],2));
        
        if ($("body.teacher-dashboard").length || $("body.client-dashboard").length){
          if (distance[index] <= 600*1000){
            if (element[index].find(".entry-button").length == false && element[index].find(".entry-urgency").length == false){
              location.reload(true);
            }
          }
          if (distance[index] == 0){
            if (element[index].find(".entry-urgency").length == false){
              location.reload(true);
            }
          }
        }
        
        
      }, 1000);
      
    });
    
  }
 
  if ($(".client-dashboard.index, .teacher-dashboard.index").length){
    if (Cookies.get('testedAudioDevices') === undefined || Cookies.get('testedVideoDevices') === undefined || Cookies.get('browserId') === undefined){
      
    } else {
      $("#test-prompt").hide();
    }
  }

  if ($(".client-dashboard.tech_check, .teacher-dashboard.tech_check").length){

    var detectFail = false; // alibistic for last steps - in case something not fine, we check all detections and ask user to fix in case we now there is something not perfect

    var deviceSelections = {
      audioinput: null,
      videoinput: null
    };

    $(".log").hide();

    function hideAll(callback){
      $("#test-part1").hide();
      $("#test-part2").hide();
      $("#test-part3").hide();
      $("#test-part4").hide();
      $("#test-fail").hide();
      $("#test-success").hide();
      callback();
    }
    hideAll(function(){});

    function addDeviceToList(item, list) {
      list = _.unionWith(list, [item], _.isEqual);
      console.log(list);
      return list;
    }

    function showAndLog(part, cls){
      console.info({"USER MESSAGE": [part, cls]});
      $("#test-"+part+" ."+cls).removeClass("d-none");
    }

    function sendLogs(){
      return new Promise(resolve => {
        var sending = console.everything.length;
        $.ajax({
          type: "POST", 
          url: "/"+$("#credentails").data("role")+"/dashboard/tech_check_js_log",
          data: {
            user_tech_check_token: $("#credentails").data("user-tech-check-token"),
            log: JSON.stringify(console.everything), 
            authenticity_token: $('[name="csrf-token"]')[0].content}
        }).done(function() {
          console.everything.splice(0, sending);
          resolve();
        });
      });
    }

    async function runTestPart1() {

      console.log("test part 1 activated");
      
      hideAll(function(){
        $("#test-part1").show();
      });

      var detectCameraFail = false;
      var detectMicrophoneFail = false;
      var detectWebRTCSupportFail = false;
      var detectWebsocketSupportFail = false;
      var detectTwilioBrowserSupportFail = false;
      var detectDesktopFail = false;

      function loadDetecRTC() {
        return new Promise(resolve => {
          DetectRTC.load(function() {

            detectCameraFail = !DetectRTC.hasWebcam;
            if (detectCameraFail){
              showAndLog("part1", "detect_camera_fail");
            }
            detectMicrophoneFail = !DetectRTC.hasMicrophone;
            if (detectMicrophoneFail){
              showAndLog("part1", "detect_microphone_fail");
            }
            detectWebRTCSupportFail = !DetectRTC.isWebRTCSupported;
            if (detectWebRTCSupportFail){
              showAndLog("part1", "detect_webrtc_support_fail");
            }
            detectWebsocketSupportFail = !(DetectRTC.isWebSocketsSupported && DetectRTC.isWebSocketsBlocked == false);
            if (detectWebsocketSupportFail){
              showAndLog("part1", "detect_websocket_support_fail");
            }
            detectTwilioBrowserSupportFail = !Video.isSupported;
            if (detectTwilioBrowserSupportFail){
              showAndLog("part1", "detect_twilio_browser_support_fail");
            }
            detectDesktopFail = DetectRTC.isMobileDevice;
            if (detectDesktopFail){
              showAndLog("part1", "detect_desktop_fail");
            }
            resolve();
          });
        });
      }

      await loadDetecRTC();

      detectFail = (detectCameraFail || detectMicrophoneFail || detectWebRTCSupportFail || detectWebsocketSupportFail || detectTwilioBrowserSupportFail || detectDesktopFail)

      if (detectFail){
        showAndLog("part1", "fix_it_please");
      }

      console.log(detectFail);

      await sendLogs();
      
      if (detectFail){
        $(".continue-anyway-button").click(function(){
          runTestPart2();
        });
        showAndLog("part1", "buttons");
      } else {
        runTestPart2();
      }

    }
    
    async function runTestPart2() {

      console.log("test part 2 activated");

      hideAll(function(){
        $("#test-part2").show();
      });

      if (DetectRTC.isWebsiteHasWebcamPermissions && DetectRTC.isWebsiteHasMicrophonePermissions){
        console.log("detected allowed access to webcam and microphone and list of cams and mics updated");
        catchUnexpectedBlockOrContinue();
      } else {
        console.log("detected restricted access to webcam and microphone, ask for allowing access");
        showAndLog("part2", "device_access_prompt_info");
        showAndLog("part2", "continue_to_allow_access");
        $("#test-part2 .continue_to_allow_access").on("click", async function(){
          catchUnexpectedBlockOrContinue();
        });
      }

      async function catchUnexpectedBlockOrContinue(){
        try{
          await updateDeviceSelectionOptions(); // very important line !
          console.log(document.querySelector('select#audioinput'));
          console.log(document.querySelector('select#audioinput'));
          // Whenever a media device is added or removed, update the list.
          navigator.mediaDevices.ondevicechange = updateDeviceSelectionOptions();
          await sendLogs();
          $("#log-part2 *").addClass("d-none");
          showAndLog("part2", "devices");
          $("#test-part2 .continue-with-devices").click(function(){
            deviceSelections.audioinput = document.querySelector('select#audioinput');
            deviceSelections.videoinput = document.querySelector('select#videoinput');
            runTestPart3();
          });
        }
        catch{
          detectFail = true;
          console.log("unexpectedly blocked access to camera or mic");
          $("#log-part2 *").addClass("d-none");
          // TODO: better fail
          showAndLog("part2", "still_no_access_to_devices_probably_because_of_block");
          showAndLog("part2", "please_check_your_browser_settings_or_contact_tech_support");
          await sendLogs();
          showAndLog("part2", "repeat");
          $(".repeat").click(function(){
            location.reload();
          });
        }
      }
      
    }

    async function runTestPart3(){

      console.log("test part 3 activated");

      hideAll(function(){
        $("#test-part3").show();
      });

      $("#test-part3 .continue-to-recording").on("click", function(){
        testCamAndMic();
        $("#test-part3 .recording-prompt").addClass("d-none");
        $("#test-part3 .continue-to-recording").addClass("d-none");
      });

      async function testCamAndMic(){

        console.log("call connectTehesoRoom");

        try{
          await connectTehesoRoom(deviceSelections.audioinput.value, deviceSelections.videoinput.value);
          setTimeout(function (){
            disconnectTehesoRoom();
            $.ajax({
              type: "POST",
              url: "/"+$("#credentails").data("role")+"/dashboard/tech_check_complete_room",
              data: {user_tech_check_token: $("#credentails").data("user-tech-check-token"), authenticity_token: $('[name="csrf-token"]')[0].content}
            }).done(function() {
              sendLogs();
              runTestPart4();
            });
          }, 10000); // How long do you want the delay to be (in milliseconds)?

          $("#progress-bar").removeClass("d-none");
          var timeleft = 10;
          var downloadTimer = setInterval(function(){
            if(timeleft <= 0){
              clearInterval(downloadTimer);
            }
            document.getElementById("progress-bar").value = 10 - timeleft;
            timeleft -= 1;
          }, 900);
        }
        catch{
          $("#media").empty();
          showAndLog("fail", "unable_connect_to_teheso");
          runTestFail();
        }
        
      }

    }

    async function runTestPart4() {

      console.log("test part 4 activated");
      
      hideAll(function(){
        $("#test-part4").show();
      });

      var failNum = 0;
      var attemptNum = 0;
      $.ajax({
        type: "POST",
        url: "/"+$("#credentails").data("role")+"/dashboard/tech_check_compose_recording",
        data: {user_tech_check_token: $("#credentails").data("user-tech-check-token"), authenticity_token: $('[name="csrf-token"]')[0].content}
      }).done(function() {
        var recordingStatusCheck = setInterval(function(){
          attemptNum++;
          $.ajax({
            type: "POST",
            url: "/"+$("#credentails").data("role")+"/dashboard/tech_check_compose_recording_status",
            data: {user_tech_check_token: $("#credentails").data("user-tech-check-token"), authenticity_token: $('[name="csrf-token"]')[0].content}
          }).done(function(data) {
            console.log({"check composition status": data})
            if(data.status == "done"){
              clearInterval(recordingStatusCheck);
              $("#test-part4 .loader").addClass("d-none");
              showAndLog("part4", "player");
              var player = videojs(
                "recording", 
                {
                  autoplay: true,
                  preload: 'auto',
                  aspectRatio: '4:3',
                  sources: [{
                    src: data.recording_url,
                    type: 'video/mp4'
                  }]
                }
              );
              $("#user-answer-fail").click(function(){
                $.ajax({
                  type: "POST",
                  url: "/"+$("#credentails").data("role")+"/dashboard/tech_check_confirmation",
                  data: {user_tech_check_token: $("#credentails").data("user-tech-check-token"), confirmation: false, authenticity_token: $('[name="csrf-token"]')[0].content}
                }).done(function(data) {
                  player.dispose();
                  runTestFail();
                });
              });
              $("#user-answer-success").click(function(){

                if(Cookies.get('browserId') != undefined){
                  var browserId = Cookies.get('browserId');
                } else {
                  var browserId = "";
                }
                var newAudioDevice = {deviceId: deviceSelections.audioinput.value, label: $("select#audioinput option[value='" + document.querySelector('select#audioinput').value + "']").text()}
                var newVideoDevice = {deviceId: deviceSelections.videoinput.value, label: $("select#videoinput option[value='" + document.querySelector('select#videoinput').value + "']").text()}

                $.ajax({
                  type: "POST",
                  url: "/"+$("#credentails").data("role")+"/dashboard/tech_check_confirmation",
                  data: {user_tech_check_token: $("#credentails").data("user-tech-check-token"), browser_id: browserId, audio_device_id: newAudioDevice.deviceId, video_device_id: newVideoDevice.deviceId, confirmation: true, authenticity_token: $('[name="csrf-token"]')[0].content}
                }).done(function(data) {
                  player.dispose();

                  Cookies.set('browserId', data.browserId, { expires: 99999 });

                  if(Cookies.get('testedAudioDevices') != undefined){
                    var testedAudioDevices = JSON.parse(Cookies.get('testedAudioDevices'));
                  } else {
                    var testedAudioDevices = [];
                  }
                  testedAudioDevices = addDeviceToList(newAudioDevice, testedAudioDevices);
                  Cookies.set('testedAudioDevices', JSON.stringify(testedAudioDevices), { expires: 99999 });
                  
                  if(Cookies.get('testedVideoDevices') != undefined ){
                    var testedVideoDevices = JSON.parse(Cookies.get('testedVideoDevices'));
                  } else {
                    var testedVideoDevices = [];
                  }
                  testedVideoDevices = addDeviceToList(newVideoDevice, testedVideoDevices);
                  Cookies.set('testedVideoDevices', JSON.stringify(testedVideoDevices), { expires: 99999 });

                  runTestSuccess();
                });
              });
              sendLogs();
            }
            else if (data.status == "no_recording"){
              clearInterval(recordingStatusCheck);
              $("#test-part4 .loader").addClass("d-none");
              showAndLog("fail", "no_recording");
              sendLogs();
              runTestFail();
            }
            else if (data.status == "teheso_fail"){
              clearInterval(recordingStatusCheck);
              $("#test-part4 .loader").addClass("d-none");
              showAndLog("fail", "something_wrong_please_contact_us");
              sendLogs();
              runTestFail();
            }
            else if(data.status != "waiting"){
              failNum++;
              if (failNum > 5){
                clearInterval(recordingStatusCheck);
                showAndLog("fail", "something_wrong_please_contact_us");
                sendLogs();
                runTestFail();
              }
            }
          });

          if (attemptNum == 30){
            showAndLog("part4", "longer_than_ususal");
          }
          if (attemptNum == 80){
            showAndLog("part4", "seems_our_servers_are_overloaded");
          }
          if (attemptNum > 160){
            clearInterval(recordingStatusCheck);
            showAndLog("fail", "not_processed_in_acceptable_time");
            sendLogs();
            runTestFail();
          }

        }, 3000);
      });

    }

    async function runTestFail() {
      console.log("TEST FAIL");
      hideAll(function(){
        $("#test-fail").show();
      });
      $(".it-support-link").addClass("d-none");
      if (detectFail){
        showAndLog("fail", "please_fix_reported_problems_and_try_again");
      } else {
        showAndLog("fail", "there_is_a_tricky_problem_please_contact_tech_support");
        showAndLog("fail", "it-support-link");
      }
      sendLogs();
    }

    async function runTestSuccess() {
      hideAll(function(){
        $("#test-success").show();
      });
      
      console.log("TEST SUCCESS DONE");

      showAndLog("success", "you_are_ready_thank_you");
      sendLogs();
    }
    
    runTestPart1();
    
  }

  if ($(".admin-clients.new, .admin-clients.edit").length) {
  
    if($("#client_inv_copy").attr('checked')) {
      $("#inv_info").css("display", "none");
    }
  
    $("#client_inv_copy").change(function(){
      if(this.checked) {
        $("#inv_info").css("display", "none");
      } else {
        $("#inv_info").css("display", "block");
      }
    });

    $("#client_account_id").change(function(){
      if(this.value) {
        $("#account_info").css("display", "none");
      } else {
        $("#account_info").css("display", "block");
      }
    });
  
  }
  
  if ($(".client-self-edit-form").length) {
    if($("#client_inv_copy").attr('checked')) {
      $("#inv_info").css("display", "none");
    }
    
    $("#client_inv_copy").change(function(){
      if(this.checked) {
        $("#inv_info").css("display", "none");
      } else {
        $("#inv_info").css("display", "block");
      }
    });
    
    
    if(!$("#client_is_company").attr('checked')) {
      $("#company").css("display", "none");
    }
    
    $("#client_is_company").change(function(){
      if(this.checked) {
        $("#company").css("display", "block");
      } else {
        $("#company").css("display", "none");
        $("#client_account_attributes_company_id").val("");
        $("#client_account_attributes_vat_id").val("");
      }
    });
    
  }

  
  if ($(".admin-lessons.index, .admin-credit_orders.index, .admin-asset_subscription_orders.index").length){

    $('.filter-date .date').datepicker({
      language: $(".date-locale").val(),
      'autoclose': true,
      'startDate': startDate,
      'endDate': moment(startDate).add(30, 'days').toDate()
    });

    $('.filter-date .date.start').datepicker('setDate', new Date($("#filterrific_start_date_start_date").val()));
    $('.filter-date .date.end').datepicker('setDate', new Date($("#filterrific_end_date_end_date").val()));

    // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
    // January - 0, February - 1, etc.
    $('.filter-date .date.start').datepicker().on('changeDate', function (ev) {
      $("#filterrific_start_date_start_date").val(ev.date.getFullYear() + '-' + ('0' + (ev.date.getMonth() + 1)).slice(-2) + '-' + ('0' + ev.date.getDate()).slice(-2));
    });
    $('.filter-date .date.end').datepicker().on('changeDate', function (ev) {
      $("#filterrific_end_date_end_date").val(ev.date.getFullYear() + '-' + ('0' + (ev.date.getMonth() + 1)).slice(-2) + '-' + ('0' + ev.date.getDate()).slice(-2));
    });
  
  }

});


function stickySticked(elem, firstPos) {
  if ( (firstPos - $(window)['scrollTop']()) <= 0) {
    $(".sticky").addClass("sticked");
  } else {
    $(".sticky").removeClass("sticked");
  }
}

function getWindowRelativeOffset(parentWindow, elem) {
  var offset = {
      left : 0,
      top : 0
  };
  // relative to the target field's document
  offset.left = elem.getBoundingClientRect().left;
  offset.top = elem.getBoundingClientRect().top;
  // now we will calculate according to the current document, this current
  // document might be same as the document of target field or it may be
  // parent of the document of the target field
  var childWindow = elem.document.frames.window;
  while (childWindow != window) {
    offset.left = offset.left + childWindow.frameElement.getBoundingClientRect().left;
    offset.top = offset.top + childWindow.frameElement.getBoundingClientRect().top;
    childWindow = childWindow.parent;
  }
  return offset;
};

function zerofill(number, length) {
  // Setup
  var result = number.toString();
  var pad = length - result.length;

  while(pad > 0) {
    result = '0' + result;
    pad--;
  }
  return result;
}

function set_new_lesson_timepicker(){
  $('#lesson_start_time_input').timepicker({
    'showDuration': false,
    'timeFormat': 'H:i',
    'show2400': true,
    'minTime': $('#lesson_start_time_input').attr("data-start-time"),
    'defaultTime': $('#lesson_start_time_input').attr("data-default-time"),
    'maxTime': $('#lesson_start_time_input').attr("data-end-time")
  });
  $('#lesson_start_time_input').val($('#lesson_start_time_input').attr("data-default-time"));
}

function start_preloader(e) {
  elem = $(e).find(".btn");
  elem.after('<div class="btn btn-light">...</div>');
  elem.remove();
}

function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
};



export default function client_booking_index_reactivate(){
  
  $(".teacher-profile-right").each(function(index) {
    $(this).find(".tab-pane").matchHeight(false);
  });
  
  //$(".tab-pane").matchHeight(false);
  
  $(".teacher-profile-right .nav-link").click(function(){
    var targetClass = $(this).attr('data-target');
    $(".teacher-profile-right .nav-link").removeClass("active");
    $(".teacher-profile-right .nav-link"+targetClass).addClass("active");
    $(".teacher-profile-right .tab-pane").removeClass("active");
    $(".teacher-profile-right .tab-pane"+targetClass).addClass("active");
    $.ajax({
      type: "POST", 
      url: "/client/booking/set_booking_tab",
      data: {target_class: targetClass, authenticity_token: $('[name="csrf-token"]')[0].content}
    });
  });
  
}



/*
function readaptive (selector, options) {
  
  if (!options) options = {};
  
  var dashjs = window.dashjs;
  var plyr = window.Plyr;
  var Hls = window.Hls;

  var supportsDash = typeof (window.MediaSource || window.WebKitMediaSource) === 'function';
  var supportsHls = Hls.isSupported();

  var player, i, players = document.querySelectorAll(selector);
  
  var adaptiveSources = [];
  var nonAdaptiveSources = [];
  
  // pro každou instanci přehrávače na stránce:
  for (var i = 0; i < players.length; i++) {
    player = players[i];
    var source, j, sources = player.getElementsByTagName('source');

    var autoplay = false;
    if ('autoplay' in options) {
      autoplay = options.autoplay;
    } else if (player.getAttribute('autoplay')) {
      autoplay = true;
    }

    if (sources.length < 1) {
      return console.error('No sources found in player');
    }
    
    for (var j = 0; j < sources.length; j++) {
      
      source = sources[j];

      var src = source.getAttribute('src');
      var type;
      if ((type = source.getAttribute('type'))) {
        type = type.split(' ')[0].split(';')[0].split('/')[1];
      }
      if (`${src}`.match(/\.m3u8$/)) {
        type = 'hls';
      } else if (`${src}`.match(/\.mpd$/)) {
        type = 'dash';
      } else if (!type) {
        type = src.split('.').pop();
      }

      if (type === 'hls') {
        if (supportsHls) { // pokud je podporován HLS
          // https://codepen.io/sampotts/pen/JKEMqB
          var hls = new Hls();
          hls.loadSource(src);
          hls.attachMedia(player);
          hls.on(Hls.Events.MANIFEST_PARSED, function () {
            if (autoplay) {
              player.play();
            }
          });
          adaptiveSources.push({type, source});
        }
      } else if (type === 'dash') {
        if (supportsDash) { // pokud je podporován DASH
          // https://codepen.io/sampotts/pen/BzpJXN
          var dash = dashjs.MediaPlayer().create();
          dash.initialize(player, src, autoplay);
          adaptiveSources.push({type, source});
        }
      } else {
        // Non adaptive source. Let's add it so that the browser will pick the best candidate for playback
        nonAdaptiveSources.push({type, source});
      }
    }
    
    // vybereme nejvhodnější formát(y) v kontextu prohlížeče
    player.innerHTML = '';
    //var picked = [];
    if (adaptiveSources.length !== 0) {
      for (var k in adaptiveSources) {
        // Only use 1 Adaptive source; so break
        player.appendChild(adaptiveSources[k].source);
        //picked.push(adaptiveSources[k].type);
        break;
      }
    } else if (nonAdaptiveSources.length !== 0) {
      for (var k in nonAdaptiveSources) {
        // Allow the browser to pick from all non-adaptive sources
        player.appendChild(nonAdaptiveSources[k].source);
        //picked.push(nonAdaptiveSources[k].type);
      }
    } else {
      return console.error('No non-adaptive sources collected');
    }

    // https://github.com/sampotts/plyr#options
    var p = new Plyr(player, {
      autoplay: autoplay,
      controls: [
        'play-large', // The large play button in the center
        //'restart', // Restart playback
        //'rewind', // Rewind by the seek time (default 10 seconds)
        'play', // Play/pause playback
        //'fast-forward', // Fast forward by the seek time (default 10 seconds)
        'progress', // The progress bar and scrubber for playback and buffering
        //'current-time', // The current time of playback
        'duration', // The full duration of the media
        'mute', // Toggle mute
        'volume', // Volume control
        //'captions', // Toggle captions
        //'settings', // Settings menu
        //'pip', // Picture-in-picture (currently Safari only)
        //'airplay', // Airplay (currently Safari only)
        //'download', // Show a download button with a link to either the current source or a custom URL you specify in your options
        'fullscreen', // Toggle fullscreen
      ]
    });
    
  }
  
  // return { picked };
  
}
*/