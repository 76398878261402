if (console.everything === undefined){
  console.everything = [];

  console.defaultLog = console.log.bind(console);
  console.log = function(){
    console.everything.push({"type":"log", "datetime":Date().toLocaleString(), "value":Array.from(arguments)});
    console.defaultLog.apply(console, arguments);
  }
  console.info = function(){
    console.everything.push({"type":"info", "datetime":Date().toLocaleString(), "value":Array.from(arguments)});
    console.defaultLog.apply(console, arguments);
  }
  console.defaultError = console.error.bind(console);
  console.error = function(){
    console.everything.push({"type":"error", "datetime":Date().toLocaleString(), "value":Array.from(arguments)});
    console.defaultError.apply(console, arguments);
  }
  console.defaultWarn = console.warn.bind(console);
  console.warn = function(){
    console.everything.push({"type":"warn", "datetime":Date().toLocaleString(), "value":Array.from(arguments)});
    console.defaultWarn.apply(console, arguments);
  }
  console.defaultDebug = console.debug.bind(console);
  console.debug = function(){
    console.everything.push({"type":"debug", "datetime":Date().toLocaleString(), "value":Array.from(arguments)});
    console.defaultDebug.apply(console, arguments);
  }
}

require('@rails/ujs').start()
require('turbolinks').start()
require('@rails/activestorage').start()
require('channels')

require('js-cookie')

require('./appstack/app')

require('./direct_uploads')
require('./autocomplete-rails')
require('./filterrific-jquery')

require('cocoon-js');

require('@fancyapps/fancybox')
require('@fancyapps/fancybox/dist/jquery.fancybox.css')

require('justifiedGallery')
require('justifiedGallery/dist/css/justifiedGallery.css')

require('bootstrap-datepicker')
require('bootstrap-datepicker/dist/css/bootstrap-datepicker.css')

require('timepicker')

require('jquery.scrollto')

require('jquery-ui/ui/widgets/autocomplete')

require('flag-icon-css/css/flag-icon.css')

require('autosize')

require('@client-side-validations/simple-form/dist/simple-form.bootstrap4')

require('bs-stepper/dist/js/bs-stepper.js')

require('jquery-match-height')

require('video.js/dist/video-js.css')

require('./twilio-utils-old')
require('./twilio-utils')
require('./waveform')

require('json-formatter-js')
require('lodash')

require('./teheso')

