// highly inspired by https://github.com/twilio/video-quickstart-js/blob/master/examples/mediadevices/src/index.js

'use strict';

import autosize from 'autosize';
import Cookies from 'js-cookie';

var Video = require('twilio-video');

var waveform = require('./waveform.js');

let activeRoom = null;
let activeTestPreviewTracks = [];

var deviceSelections = {
  audioinput: null,
  videoinput: null
};

var lessonJsLogger;

//const images = require.context('../images', true)
//const imagePath = (name) => images(name, true)

function getRoomCredentials(){
  return {identity: $("#credentails").data("identity"), token: $("#credentails").data("token"), roomName: $("#credentails").data("room-name"), role: $("#credentails").data("role")}
}

/**
 * Build the list of available media devices.
 */
export function updateDeviceSelectionOptions() {
  return new Promise(resolve => {
    console.log("updating device selection");
    if (deviceSelections.audioinput == null || deviceSelections.audioinput == null){
      deviceSelections.audioinput = document.querySelector('select#audioinput');
      deviceSelections.videoinput = document.querySelector('select#videoinput');
    }
    return resolve(getDeviceSelectionOptions()
        .then(function(deviceSelectionOptions) {
          ['audioinput', 'videoinput'].forEach(function(kind) {
            var kindDeviceInfos = deviceSelectionOptions[kind];
            var select = deviceSelections[kind];

            [].slice.call(select.children).forEach(function(option) {
              option.remove();
            });

            kindDeviceInfos.forEach(function(kindDeviceInfo) {
              var deviceId = kindDeviceInfo.deviceId;
              var label = kindDeviceInfo.label || 'Device [ id: '
                  + deviceId.substr(0, 5) + '... ]';

              var option = document.createElement('option');
              option.value = deviceId;
              option.appendChild(document.createTextNode(label));
              select.appendChild(option);
            });
          });
          return deviceSelectionOptions;
        }));
  });
}

// Get the Participant's Tracks.
function getTracks(participant) {
  return Array.from(participant.tracks.values()).filter(function(publication) {
      return publication.track;
    }).map(function(publication) {
      return publication.track;
    });
  }

// Attach the Track to the DOM.
function attachTrack(track, container) {
  console.log("(attachTrack) added track " + track.kind + " to DOM");
  container.appendChild(track.attach());
  $("#remote-media video").height($("#remote-media").height());
}

// Attach the Tracks to the DOM.
function attachTracks(tracks, container) {
  tracks.forEach(function(track) {
    console.log("(attachTracks) added track: " + track.kind + " id: " + track.id);
    //detachTracks([track]); // prepare array for future detach
    attachTrack(track, container);
  });
}

// Detach given track from the DOM
function detachTrack(track) {
  track.detach().forEach(function(element) {
    element.remove();
  });
}

// A new RemoteTrack was published to the Room.
function trackPublished(publication, container) {
  console.log('Track was of kind ' + publication.kind + ' was published:' + publication.isSubscribed);
  if (publication.isSubscribed) {
    attachTrack(publication.track, container);
  }
  publication.on('subscribed', function(track) {
    console.log('Subscribed to ' + publication.kind + ' track');
    attachTrack(track, container);
  });
  publication.on('unsubscribed', detachTrack);
}

// A RemoteTrack was unpublished from the Room.
function trackUnpublished(publication) {
  console.log(publication.kind + ' track was unpublished.');
}

// A new RemoteParticipant joined the Room
function participantConnected(participant, container) {
  console.log("Participant '" + participant.identity + "' joined the room");
  let selfContainer = document.createElement('div');
  selfContainer.id = `participantContainer-${participant.identity}`;

  container.appendChild(selfContainer);

  participant.tracks.forEach(function(publication) {
    trackPublished(publication, selfContainer);
  });
  participant.on('trackPublished', function(publication) {
    trackPublished(publication, selfContainer);
  });
  participant.on('trackUnpublished', trackUnpublished);
}

// Detach the Participant's Tracks from the DOM.
function detachParticipantTracks(participant) {
  var tracks = getTracks(participant);
  tracks.forEach(detachTrack);
}

// reads selected audio input, and updates preview and room to use the device.
function applyAudioInputDeviceChange(event) {
  var audio = document.querySelector('audio#audioinputpreview');
  var waveformContainer = document.querySelector('div#audioinputwaveform');
  if (event) {
    event.preventDefault();
    event.stopPropagation();
  }

  return applyAudioInputDeviceSelection(deviceSelections.audioinput.value, audio, activeRoom).then(function() {
    if (audio.srcObject) {
      var canvas = waveformContainer.querySelector('canvas');
      waveform.setStream(audio.srcObject);
      if (!canvas) {
        waveformContainer.appendChild(waveform.element);
      }
    }
  });

}

// reads selected video input, and updates preview and room to use the device.
function applyVideoInputDeviceChange(event) {
  try {
    var video = document.querySelector('video#videoinputpreview');
    applyVideoInputDeviceSelection(deviceSelections.videoinput.value, video, activeRoom);
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
  } catch (error) {
    console.log('videoInput apply failed:', error);
  }
}

export async function lessonJsLog(){
  return new Promise((resolve) => {

    var data = getRoomCredentials();
    console.log("sending console data");
    var sending = console.everything.length;

    // Note: cache should not be re-used by repeated calls to JSON.stringify.
    var cache = [];
    var clean_string = JSON.stringify(console.everything, function(key, value) {
        if (typeof value === 'object' && value !== null) {
            if (cache.indexOf(value) !== -1) {
                // Duplicate reference found, discard key
                return;
            }
            // Store value in our collection
            cache.push(value);
        }
        return value;
    });
    cache = null; // Enable garbage collection

    $.ajax({
      type: "POST", 
      url: "/"+data.role+"/dashboard/lesson_js_log",
      data: {twilio_token: data.roomName, log: clean_string, authenticity_token: $('[name="csrf-token"]')[0].content}
    }).done(function() {
      console.everything.splice(0, sending);
    }).always(function() {
      resolve();
    });

  });
}

function printNetworkQualityStats(networkQualityLevel, networkQualityStats) {
  // Print in console the networkQualityLevel using bars
  console.log({
    0: '▃▃▃ no connection ▃▃▃',
    1: '▃',
    2: '▃▄',
    3: '▃▄▅',
    4: '▃▄▅▆',
    5: '▃▄▅▆▇'
  }[networkQualityLevel] || '');
  $("#network .value").html(
    {
      0: $("#network").data("network-0"),
      1: $("#network").data("network-1"),
      2: $("#network").data("network-2"),
      3: $("#network").data("network-3"),
      4: $("#network").data("network-4"),
      5: $("#network").data("network-5")
    }[networkQualityLevel] || ''
  );
  
  if(networkQualityLevel > 0 && networkQualityLevel < 2){
    $("#low-quality").removeClass("d-none");
  } else {
    $("#low-quality").addClass("d-none");
  }

  if (networkQualityStats) {
    // Print in console the networkQualityStats, which is non-null only if Network Quality
    // verbosity is 2 (moderate) or greater
    console.log('Network Quality statistics:', networkQualityStats);
  }
}

export async function connectTehesoRoom(audioinput, videoinput) {

  return new Promise((resolve) => {
    async function execute(){      
      
      //mediaContainer = document.getElementById('remote-media');

      if (activeRoom) {
        activeRoom.disconnect();
        activeRoom = null;
      } else {

        console.log(["audioinputSelected", JSON.stringify(audioinput)]);
        console.log(["videoinputSelected", JSON.stringify(videoinput)]);

        const data = await getRoomCredentials();
        activeRoom = await connectWithSelectedDevices(data, audioinput, videoinput);

        console.log(['activeRoom:', JSON.stringify(activeRoom)]);

        if ($(".client-dashboard.tech_check, .teacher-dashboard.tech_check").length){

          // sync the preview with connected tracks.
          applyVideoInputDeviceChange();
          applyAudioInputDeviceChange();

        } else {

          lessonJsLogger = window.setInterval(function(){lessonJsLog();}, 15000);

          connectTehesoChat();

          $.ajax({
            type: "POST", 
            url: "/"+data.role+"/dashboard/lesson_browser_and_devices_log",
            data: {
              twilio_token: data.roomName, 
              browser_id: Cookies.get('browserId'),
              audio_device_id: audioinput,
              video_device_id: videoinput,
              audio_device_label: $("select#audioinput option[value='" + videoinput + "']").text(),
              video_device_label: $("select#videoinput option[value='" + videoinput + "']").text(),
              tested_audio_devices: Cookies.get('testedAudioDevices'),
              tested_video_devices: Cookies.get('testedVideoDevices'),
              authenticity_token: $('[name="csrf-token"]')[0].content
            }
          }).done(function() {
            console.log("browser id and devices id logged");
          });

          // Attach LocalParticipant's Tracks, if not already attached.
          var previewContainer = document.getElementById('local-media');
          if (!previewContainer.querySelector('video')) {
            attachTracks(getTracks(activeRoom.localParticipant), previewContainer);
            activeRoom.localParticipant.on('networkQualityLevelChanged', printNetworkQualityStats);
          }

          //activeRoom.participants.forEach(participantConnected);

          // listen as participants connect/disconnect
          //activeRoom.on('participantConnected', participantConnected);
          //activeRoom.on('participantDisconnected', participantDisconnected);

          ////////////////////////
          // Attach the Tracks of the Room's Participants.
          var remoteMediaContainer = document.getElementById('remote-media');
          activeRoom.participants.forEach(function(participant) {
            console.log("Already in Room: '" + participant.identity + "'");
            participantConnected(participant, remoteMediaContainer);
          });

          // When a Participant joins the Room, log the event.
          activeRoom.on('participantConnected', function(participant) {
            console.log("Joining: '" + participant.identity + "'");
            participantConnected(participant, remoteMediaContainer);
          });

          // When a Participant leaves the Room, detach its Tracks.
          activeRoom.on('participantDisconnected', function(participant) {
            console.log("RemoteParticipant '" + participant.identity + "' left the room");
            detachParticipantTracks(participant);
          });
          
        }
        

        // Once the LocalParticipant leaves the room, detach the Tracks
        // of all Participants, including that of the LocalParticipant.
        activeRoom.on('disconnected', function() {
          console.log('Left');
          lessonJsLog(data);
          clearInterval(lessonJsLogger);
          detachParticipantTracks(activeRoom.localParticipant);
          activeRoom.participants.forEach(detachParticipantTracks);
          activeRoom = null;
        });
        ////////////////////////

      }
      //buttonDisconnect.disabled = false;
      resolve();
    }
    execute();
  });



}

export function disconnectTehesoRoom() {
  console.log("DISCONNECT");
  activeTestPreviewTracks.forEach(function(track) { 
    track.stop();
  });
  if (activeRoom) {
    activeRoom.disconnect();
    activeRoom = null;
  }
}

// Apply the selected audio output media device.
// NOTE: safari does not let us query the output device (and its HTMLAudioElement does not have setSinkId)
//document.querySelector('button#audiooutputapply').onclick = applyAudioOutputDeviceChange();

// Disconnect from the Room on page unload.
window.onbeforeunload = function() {
  if (activeRoom) {
    activeRoom.disconnect();
    activeRoom = null;
  }
};

/**
 * Get the list of available media devices of the given kind.
 * @param {Array<MediaDeviceInfo>} deviceInfos
 * @param {string} kind - One of 'audioinput', 'audiooutput', 'videoinput'
 * @returns {Array<MediaDeviceInfo>} - Only those media devices of the given kind
 */
function getDevicesOfKind(deviceInfos, kind) {
  return deviceInfos.filter(function(deviceInfo) {
    return deviceInfo.kind === kind;
  });
}


/**
 * Apply the selected audio input device.
 * @param {string} deviceId
 * @param {HTMLAudioElement} audio
 * @param {Room} [room] - The Room, if you have already joined one
 * @returns {Promise<void>}
 */

function applyAudioInputDeviceSelection(deviceId, audio, room) {
  return Video.createLocalAudioTrack({
    deviceId: {
      exact: deviceId // NOTE: on ios safari - it respects the deviceId only if its exact.
    }
  }).then(function(localTrack) {
    activeTestPreviewTracks.push(localTrack); // ADDED ADDED ADDED ADDED ADDED ADDED ADDED ADDED ADDED
    localTrack.attach(audio);
    if (room) {
      switchLocalTracks(room, localTrack);
    }
  }).catch(function(error) {
    console.log('applyAudioInputDeviceSelection failed:', error);
  });
}


/**
 * Apply the selected video input device.
 * @param {string} deviceId
 * @param {HTMLVideoElement} video
 * @param {Room} [room] - The Room, if you have already joined one
 * @returns {Promise<void>}
 */

function applyVideoInputDeviceSelection(deviceId, video, room) {
  return Video.createLocalVideoTrack({
    deviceId: {
      exact: deviceId
    }
  }).then(function(localTrack) {
    activeTestPreviewTracks.push(localTrack); // ADDED ADDED ADDED ADDED ADDED ADDED ADDED ADDED ADDED
    localTrack.attach(video);
    if (room) {
      switchLocalTracks(room, localTrack);
    }
  }).catch(function(error) {
    console.log('applyVideoInputDeviceSelection failed:', error);
  });
}


/**
 * Ensure that media permissions are obtained.
 * @returns {Promise<void>}
 */
function ensureMediaPermissions() {
  return navigator.mediaDevices.enumerateDevices().then(function(devices) {
    return devices.every(function(device) {
      return !(device.id && device.label);
    });
  }).then(function(shouldAskForMediaPermissions) {
    if (shouldAskForMediaPermissions) {
      return navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(function(mediaStream) {
        mediaStream.getTracks().forEach(function(track) {
          track.stop();
        });
      });
    }
  });
}

/**
 * Get the list of available media devices.
 * @returns {Promise<DeviceSelectionOptions>}
 * @typedef {object} DeviceSelectionOptions
 * @property {Array<MediaDeviceInfo>} audioinput
 * @property {Array<MediaDeviceInfo>} videoinput
 */
function getDeviceSelectionOptions() {
  // before calling enumerateDevices, get media permissions (.getUserMedia)
  // w/o media permissions, browsers do not return device Ids and/or labels.
  return ensureMediaPermissions().then(function() {
    return navigator.mediaDevices.enumerateDevices().then(function(deviceInfos) {
      var kinds = ['audioinput', 'videoinput'];
      return kinds.reduce(function(deviceSelectionOptions, kind) {
        deviceSelectionOptions[kind] = getDevicesOfKind(deviceInfos, kind);
        console.log(["getDeviceSelectionOptions returns deviceSelectionOptions:", JSON.stringify(deviceSelectionOptions)])
        return deviceSelectionOptions;
      }, {});
    });
  });
}

/**
 * Connects to room using specified input devices
 * @param {obsject} data
 * @param {string} audioDeviceId
 * @param {string} videoDeviceId
 * @returns {Promise<Room>}
 */
function connectWithSelectedDevices(data, audioDeviceId, videoDeviceId) {
  var default_options = {
    name: data.roomName,
    logLevel: 'info',
    maxAudioBitrate: 16000, //For music remove this line
    //For multiparty rooms (participants>=3) uncomment the line below
    //preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
    networkQuality: {local:1, remote: 1}  
  }

  var audio_options = {}
  if (audioDeviceId != undefined){
    audio_options = {audio: { deviceId: { exact: audioDeviceId } }}
  } else {
    audio_options = {audio: true}
  }

  var video_options = {}
  if (videoDeviceId != undefined){
    video_options = {video: { deviceId: { exact: videoDeviceId } }}
  }
  
  if ($(".client-dashboard.tech_check, .teacher-dashboard.tech_check").length){
    var size_options = {
      video: { height: 480, width: 640, frameRate: 24 }
    }
  } else {
    var size_options = {
      video: { height: 720, width: 1280, frameRate: 24 },
      bandwidthProfile: {
        video: {
          mode: 'grid',
          maxTracks: 2,
          renderDimensions: {
            high: {height:720, width:1280},
            standard: {height:540, width:960},
            low: {height:176, width:288}
          }
        }
      }
    }
  }

  
  var options = _.merge(default_options, audio_options, video_options, size_options);
  console.log(["roomOptions:", JSON.stringify(options)]);

  return Video.connect(data.token, options);
}





///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
// CHAT :








// Helper functions:

// Helper function to print info messages to the chat window
function print($chatWindow, infoMessage, asHtml) {
  var $msg = $('<div class="info">');
  if (asHtml) {
    $msg.html(infoMessage);
  } else {
    $msg.text(infoMessage);
  }
  $chatWindow.append($msg);
  $('.lastmessage').remove();
  $('#messages').append('<div class="lastmessage"></div>');
}

export function connectTehesoChat(token, role, channel_name){

console.log("chat init");

var data = getRoomCredentials();
var token = data.token;
var role = data.role;
var channel_name = data.roomName;

// Get handle to the chat div
var $chatWindow = $('#messages');

// Our interface to the Chat service
var chatClient;

// Alert the user they have been assigned a random username
chatClient = new Twilio.Chat.Client(token);

console.log("chatClientInit");

chatClient.getSubscribedChannels().then(function(){

  chatClient.getChannelByUniqueName(channel_name).then(function(channel) {

    console.log(channel_name);
    console.log(channel);

    var badgesAssets = $("#chat").data("badges-assets");
    var badgesList = $("#chat").data("badges-list");

    channel.join().then(function() {
      print($chatWindow, 'Joined!', true);
      $('#messages').append('<div class="lastmessage"></div>');
      $('#messages .lastmessage').get(0).scrollIntoView();
    });

    // Listen for new messages sent to the channel
    channel.on('messageAdded', function(message) {
      chatClient.getUser(message.author).then(function(user){
        var messageAuthorFriendly = user.friendlyName;
        var $user = $('<span class="username">').text(messageAuthorFriendly + ': ');
        var splittedAuthor = message.author.split("-");
        $user.addClass(splittedAuthor[splittedAuthor.length-1]);
        var $message = $('<span class="message">').text(message.body);
        var $container = $('<div class="message-container">');

        if((message.body.split(":")[0] == "badge") && badgesList.includes(message.body.split(":")[1])){
          $container.append(badgesAssets[message.body.split(":")[1]]);
        } else {
          $container.append($user);
          $container.append($message);
        }

        $chatWindow.append($container);
        $(".remote-wrapper").addClass("chat-open");
        $("#chat").show();
        $("#button-send").show();
        $('.lastmessage').remove();
        $('#messages').append('<div class="lastmessage"></div>');
        $('#messages .lastmessage').get(0).scrollIntoView();
      });
    });
    
    // Send a new message to the general channel
    var $input = $('#chat-input');
    $input.on('keydown', function(e) {
      if (e.keyCode == 13) {
        channel.sendMessage($input.val()).then(function(){
          $input.val('');
          autosize.update($('textarea'));
        });
      }
    });
    $("#button-send").on('click', function(e){
      if($input.val() != ''){
        channel.sendMessage($input.val()).then(function(){
          $input.val('');
          autosize.update($('textarea'));
        });
      }
    });
    $("#badges").hide();
    $("#button-badges").on('click', function(e){
      $("#badges").toggle();
    });
    $(".badge-link").on('click', function(e){
      channel.sendMessage($(this).data("badge")).then(function(){
        $("#badges").hide();
      });
    });
    
  })/*.catch(function() {
    // If it doesn't exist
    print($chatWindow, 'Chat is not connected :-(');
    console.log(channel_name);
  })*/;
});

}