

  import '/tmp/build_9e1ead22/app/webpacker/application/fonts/fa-solid-900.svg';

  import '/tmp/build_9e1ead22/app/webpacker/application/fonts/fa-brands-400.eot';

  import '/tmp/build_9e1ead22/app/webpacker/application/fonts/fa-brands-400.woff2';

  import '/tmp/build_9e1ead22/app/webpacker/application/fonts/fa-solid-900.ttf';

  import '/tmp/build_9e1ead22/app/webpacker/application/fonts/fa-regular-400.woff2';

  import '/tmp/build_9e1ead22/app/webpacker/application/fonts/fa-regular-400.ttf';

  import '/tmp/build_9e1ead22/app/webpacker/application/fonts/fa-solid-900.woff';

  import '/tmp/build_9e1ead22/app/webpacker/application/fonts/fa-brands-400.woff';

  import '/tmp/build_9e1ead22/app/webpacker/application/fonts/fa-solid-900.woff2';

  import '/tmp/build_9e1ead22/app/webpacker/application/fonts/fa-regular-400.eot';

  import '/tmp/build_9e1ead22/app/webpacker/application/fonts/fa-solid-900.eot';

  import '/tmp/build_9e1ead22/app/webpacker/application/fonts/fa-regular-400.woff';

  import '/tmp/build_9e1ead22/app/webpacker/application/fonts/fa-brands-400.svg';

  import '/tmp/build_9e1ead22/app/webpacker/application/fonts/fa-regular-400.svg';

  import '/tmp/build_9e1ead22/app/webpacker/application/fonts/fa-brands-400.ttf';
