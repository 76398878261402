'use strict';

var Video = require('twilio-video');

var activeRoom;
var previewTracks;
var identity;
var roomName;
var lessonJsLogger;

// Attach the Track to the DOM.
function attachTrack(track, container) {
  container.appendChild(track.attach());
}

// Attach array of Tracks to the DOM.
function attachTracks(tracks, container) {
  tracks.forEach(function(track) {
    attachTrack(track, container);
  });
}

// Detach given track from the DOM.
function detachTrack(track) {
  track.detach().forEach(function(element) {
    element.remove();
  });
}

// Appends remoteParticipant name to the DOM.
function appendName(identity, container) {
  const name = document.createElement('p');
  name.id = `participantName-${identity}`;
  name.className = 'instructions';
  name.textContent = identity;
  container.appendChild(name);
}

// Removes remoteParticipant container from the DOM.
function removeName(participant) {
  if (participant) {
    let { identity } = participant;
    const container = document.getElementById(
      `participantContainer-${identity}`
    );
    container.parentNode.removeChild(container);
  }
}

// A new RemoteTrack was published to the Room.
function trackPublished(publication, container) {
  if (publication.isSubscribed) {
    attachTrack(publication.track, container);
  }
  publication.on('subscribed', function(track) {
    log('Subscribed to ' + publication.kind + ' track');
    attachTrack(track, container);
  });
  publication.on('unsubscribed', detachTrack);
}

// A RemoteTrack was unpublished from the Room.
function trackUnpublished(publication) {
  log(publication.kind + ' track was unpublished.');
}

// A new RemoteParticipant joined the Room
function participantConnected(participant, container) {
  let selfContainer = document.createElement('div');
  selfContainer.id = `participantContainer-${participant.identity}`;

  container.appendChild(selfContainer);
  appendName(participant.identity, selfContainer);

  participant.tracks.forEach(function(publication) {
    trackPublished(publication, selfContainer);
  });
  participant.on('trackPublished', function(publication) {
    trackPublished(publication, selfContainer);
  });
  participant.on('trackUnpublished', trackUnpublished);
}

// Detach the Participant's Tracks from the DOM.
function detachParticipantTracks(participant) {
  var tracks = getTracks(participant);
  tracks.forEach(detachTrack);
}

// When we are about to transition away from this page, disconnect
// from the room, if joined.
window.addEventListener('beforeunload', leaveRoomIfJoined);



export async function joinTehesoRoom(data){

  return new Promise((resolve, reject) => {

    var joinLog = []; // joinLog.push({"test": "camera_detection", "type": "danger", "result": DetectRTC.hasWebcam});

    identity = data.identity;
    roomName = data.roomName;
    if (!roomName) {
      console.log("No room name!");
      return;
    }

    console.log("Joining room '" + roomName + "'...");

    var connectOptions = {
      name: roomName,
      logLevel: 'info',
      bandwidthProfile: { bandwidthProfile: "disabled" },
      maxVideoBitrate: 2000000,
      networkQuality: {local: 2, remote: 2}
    };

    if (previewTracks) {
      connectOptions.tracks = previewTracks;
    }

    // Join the Room with the token from the server and the
    // LocalParticipant's Tracks.
    Video.connect(data.token, connectOptions).then(async function(room){
      var roomJoinedLog = await roomJoined(room);
      joinLog = joinLog.concat(roomJoinedLog);

      // Bind button to leave Room.
      document.getElementById('button-leave').onclick = function () {
        log('Leaving room...');
        lessonJsLog();
        clearInterval(lessonJsLogger);
        activeRoom.disconnect();
      };
      resolve(joinLog);

    }).catch((error) => {
      console.log(error);
      console.log('Could not connect to Twilio: ' + error.message);
      joinLog.push({"test": "connect_to_twilio", "type": "danger", "result": 0});
      reject(joinLog);
    });

  });

}

// Get the Participant's Tracks.
function getTracks(participant) {
  return Array.from(participant.tracks.values()).filter(function(publication) {
      return publication.track;
    }).map(function(publication) {
      return publication.track;
    });
}

function lessonJsLog(){
  var sending = console.everything.length;
  $.ajax({
    type: "POST", 
    url: "/client/dashboard/tech_check_js_log",
    data: {user_tech_check_log_token: $("#tech-check").data("user-tech-check-log-token"), log: JSON.stringify(console.everything), authenticity_token: $('[name="csrf-token"]')[0].content}
  }).done(function() {
    console.everything.splice(0, sending);
  });
}

// Successfully connected!
function roomJoined(room) {

  var roomJoinedLog = [];

  return new Promise((resolve) => {

    window.room = activeRoom = room;

    roomJoinedLog.push({"test": "room_joined", "type": "danger", "result": 1});

    console.info(JSON.stringify(window.room));

    if (room.state == "connected"){
      roomJoinedLog.push({"test": "room_connected", "type": "danger", "result": 1});
    }
    if (room.localParticipant.state == "connected"){
      roomJoinedLog.push({"test": "room_local_participant_connected", "type": "danger", "result": 1});
    }
    room.localParticipant.videoTracks.forEach(function(t){
      if (t.isTrackEnabled){
        roomJoinedLog.push({"test": "room_video_track_enabled", "type": "danger", "result": 1});
      }
    });
    room.localParticipant.videoTracks.forEach(function(t){
      if (t.isTrackEnabled){
        roomJoinedLog.push({"test": "room_audio_track_enabled", "type": "danger", "result": 1});
      }
    });

    lessonJsLogger = window.setInterval(lessonJsLog(), 20000);

    //document.getElementById('button-join').style.display = 'none';
    document.getElementById('button-leave').style.display = 'block';

    // Attach LocalParticipant's Tracks, if not already attached.
    var previewContainer = document.getElementById('local-media');
    if (!previewContainer.querySelector('video')) {
      attachTracks(getTracks(room.localParticipant), previewContainer);
    }

    // Attach the Tracks of the Room's Participants.
    var remoteMediaContainer = document.getElementById('remote-media');
    room.participants.forEach(function (participant) {
      console.log("Already in Room: '" + participant.identity + "'");
      participantConnected(participant, remoteMediaContainer);
    });

    // When a Participant joins the Room, log the event.
    room.on('participantConnected', function (participant) {
      console.log("Joining: '" + participant.identity + "'");
      participantConnected(participant, remoteMediaContainer);
    });

    // When a Participant leaves the Room, detach its Tracks.
    room.on('participantDisconnected', function (participant) {
      console.log("RemoteParticipant '" + participant.identity + "' left the room");
      detachParticipantTracks(participant);
      removeName(participant);
    });

    // Once the LocalParticipant leaves the room, detach the Tracks
    // of all Participants, including that of the LocalParticipant.
    room.on('disconnected', function () {
      console.log('Left');
      if (previewTracks) {
        previewTracks.forEach(function (track) {
          track.stop();
        });
        previewTracks = null;
      }
      detachParticipantTracks(room.localParticipant);
      room.participants.forEach(detachParticipantTracks);
      room.participants.forEach(removeName);
      activeRoom = null;
      document.getElementById('button-join').style.display = 'block';
      document.getElementById('button-leave').style.display = 'none';
    });

    resolve(roomJoinedLog);

  });
}

// Activity log.
function log(message) {
  var logDiv = document.getElementById('log');
  logDiv.innerHTML += '<p>&gt;&nbsp;' + message + '</p>';
  logDiv.scrollTop = logDiv.scrollHeight;
}

// Leave Room.
function leaveRoomIfJoined() {
  if (activeRoom) {
    activeRoom.disconnect();
  }
}
